.breath-box {
    padding-top: 0px;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    padding-bottom:0;
}
.breath-box-inner{
    background: #fff;
    position: relative;
    border-radius: 20px;
    padding: 30px;
}
.animation-ease-in{
    transition-duration: 4s;
    transform: scale(1.2);
}
.animation-ease-out{
    transition-duration: 4s;
    transform: scale(1);
}
.hold-breath-in{
    transition-duration: 4s;
    transform: scale(1.2);
}
.hold-breath-out{
    transition-duration: 4s;
    transform: scale(1);
}
.breath-box .title {
    color: #214d4d;
    font-size: 22px;
    font-weight: 700;
}
.breath-box .breath-count {
    font-size: 35px;
    margin: 0;
    position: relative;
}
.add-num-animation{
    animation: numup 4s;
    animation-iteration-count: 4;
}
.add-dot-animation{
    animation: movedot 16s;
}
.breath-box .title {
    margin: 0;
    position: relative;
    animation: title 16s;
}
.breath-box .footer {
    border-radius: 20px 20px 0 0;
    padding-top: 17px;
    padding-bottom: 10px;
    animation: movefooter 1s;
    animation-fill-mode: forwards;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.breath-box-inner .footer .theme-btn{
    font-size:17px;
}
.breath-box .breath-dot {
    border: 4px solid #214d4d;
    margin: 0 auto;
    border-radius: 10px;
    margin-bottom: 20px;
    width: 135px;
    height: 135px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.breath-box .box-dot {
    width: 20px;
    height: 20px;
    background: #214d4d;
    position: absolute;
    top: -10px;
    border-radius: 50px;
    left: -10px;
}
.count-div {
    width: 100%;
    height: 42px;
    overflow: hidden;
}
.title-div {
    width: 100%;
    margin-bottom: 24px;
}
/* @keyframes movedot {
    0%   {top: -10px; left: -10px;}
    25%  {top: -12px; left: 128px;}
    50%  {top: 128px; left: 128px;}
    75%  {top: 128px; left: -10px;}
    100% {top: -10px; left: -10px;}
} */
@keyframes movedot {
0% {
    top: -10px;
    left: -10px;
}
25% {
    top: -12px;
    left: 115px;
}
50% {
    top: 115px;
    left: 121px;
}
75% {
    top: 118px;
    left: -10px;
}
100% {
    top: -10px;
    left: -10px;
}
}
@keyframes numup {
    from {top: 0px;}
    to {top: -200px;}
  }
  /* @keyframes titleup {
    from {top: 0px;}
    to {top: -200px;}
  } */
  /* .breath-dot {
    transform: scale(1.05);
    transition-duration: 4s;
} */
/* .title {
    margin-top: -28px !important;
    transition-delay: 16s;
} */
.topbar {
    background: #e2dfc8;
    border-radius: 0px 0px 20px 20px;
    padding-top: 10px;
    padding-bottom: 5px;
    margin-bottom: 15px;
    animation: movetopbar 1s;
    animation-fill-mode: forwards;
    position: relative;
}
.topbar h3 {
    color: #0e0e0e;
    font-size: 16px;
    margin: 0;
    padding: 8px 100px;
}

@keyframes movetopbar {
    from {top: -73px;}
    to {top: 0px;}
  }
  @keyframes movefooter {
    from {top: 78px;}
    to {top: 0px;}
  }

  