.skill-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    justify-content: space-between;
    background: #fff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 0 10px #0000001a;
    position:relative;
}
.skill-img{
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 15px;
    min-width:60px;
}
.skill-img img{
    width:100%;
}
.skill-content{
    text-align: left;
    padding-left: 15px;
}
.related-stress {
    font-size: small;
}
.related-stress span{
    font-weight: 600;
    font-size: 13px;
    line-height: 1.3;
    color: #437273;
    letter-spacing: .4px;

}
.skill-title span:first-child{
    font-weight: 700;
    font-size: 18px;
    line-height: 1.5;
    color:#214D4D;
    letter-spacing: .4px;
}
.skill-title span:nth-child(2){
    font-size:small;
    padding-left:10px;
}
.head-title{
    font-weight: 700;
    text-align: left;
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 26px;
}
.description {
    font-size: small;
    text-align: left;
    margin-bottom: 20px;
}
.like-button{
    border:none;
    background:transparent
}
.ph-heart{
    position: relative;
    display:block
}
.heart { 
    position: relative; 
} 

.heart:before, .heart:after { 
    position: absolute;
    content: "";
    left: 0px;
    top: 0;
    width: 10px;
    height: 15px;
    background: #bba9a9;
    border-radius: 50px 50px 0 0;
    transform: rotate(-45deg);
    transform-origin: 0 100%;
}
.like-button.active .heart:before, .like-button.active .heart:after {
    background: red;
}
.like-button.active .ph-heart img {
    filter: invert(0.5) sepia(1) saturate(118) hue-rotate(-27deg);
}
.content-left {
    display: flex;
    align-items: center;
    justify-content: center;
}
.heart:after { 
    left: -10px; 
    transform: rotate(45deg); 
    transform-origin :100% 100%; 
}
span.completed {
    background: #19ce00;
    color: #fff;
    padding: 1px 5px;
    border-radius: 30px;
    line-height: 16px !important;
    font-size: 12px !important;
    font-weight: 600 !important;
}
.skills.filter form{
    position: relative;
}

