.user-info{
    display: block;
    text-align: left;
    font-size: small;
    border-bottom: 1px solid #00000017;
    padding-bottom: 15px;
    margin-bottom:15px;
}
.user-settings{
    text-align: left;
}
.user-title{
    font-weight:500;
    margin-bottom: 10px;
}
.user-details li{
    list-style-type: none;
    padding:6px 0;
}
.user-details{
    margin:0;
    padding:0;
    font-size: small;
    position: relative;
}
.user-details li:before {
    content: "";
    width: 6px;
    height: 6px;
    display: block;
    border-top: 1px solid #000;
    border-right: 1px solid #000;
    transform: rotate(45deg);
    position: absolute;
    right: 0;
    margin-top: 10px;
}
