.credit-card {
    width: 300px;
    height: 180px;
    border-radius: 10px;
    background: linear-gradient(135deg, #667eea, #764ba2);
    color: white;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-family: 'Arial', sans-serif;
    position:relative;
    }
    
    .credit-card__logo {
    font-size: 24px;
    font-weight: bold;
    text-align: right;
    }
    
    .credit-card__number {
    font-size: 20px;
    margin: 20px 0;
    letter-spacing: 2px;
    }
    
    .credit-card__details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    }
    
    .credit-card__name,
    .credit-card__expiry {
    font-size: 16px;
    }
    .card-wrap{
        display:flex;
        justify-content: center;
        margin-bottom: 15px;
        position:relative;
    }
    
    .default-method .dot{
        width: 20px;
        height: 20px;
        background: #ffffff99;
        display: block;
        border-radius: 50%;
        border: 2px solid #fff;
    }
    .default-method .dot.default{
        background: #00c01a;
    }
    .credit-card:hover .default-method .dot{
        width: 100%;
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 10px;
        background: #000000ba;
    }
    .default-method .dot:hover:before{
        content: "Make it default";
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: #fff;
    }
    
    .default-method{
        display: grid;
        justify-content: flex-end;
        margin-top: 15px;
        margin-right: -15px;
    }
    .card-wrap button.close-btn.theme-btn{
        right: -15px;
        top: -24px;
        z-index: 5;
    }
    .success{
        color:#00c01a;
    }
    .credit-card, button,a, .subscription-nav, .create-method-link span{
        cursor: pointer;
    }
    .items .head-title{
        margin-top:20px;
    }

/*OrderThankYou.css */
.thank-you-page {
    font-family: 'Arial', sans-serif;
    color: #333;
    }
    
    .container {
    margin: 0 auto;
    }
    
    /* .padding30 {
    padding: 30px 0;
    } */
    
    /* .content-wrap {
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    } */
    
    .thank-you-header {
    text-align: center;
    margin-bottom: 20px;
    }
    
    .thank-you-header h1 {
    font-size: 24px;
    color: #4CAF50;
    }
    
    .thank-you-header p {
    font-size: 16px;
    color: #777;
    }
    
    .card {
    background: #7b9c9c;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
    color: #fff;
    }
    .order-summary.card ul{
        color:#777;
    }
    .footer-details.order button{
        background: #214d4d;
        color: #fff;
        padding: 5px 10px;
        border-radius: 20px;
    }
    .card h2 {
    font-size: 20px;
    margin-bottom: 10px;
    }
    
    .card p {
    font-size: 14px;
    margin: 5px 0;
    }
    
    .card ul {
    list-style: none;
    padding: 0;
    }
    
    .card ul li {
    background: #fff;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    }
    
    .footer-main {
    text-align: center;
    overflow:hidden;
    }

    .mask-with-ellips img {
    max-width: 100%;
    height: auto;
    }
    .table-responsive {
        width: 100%;
        overflow-x: auto;
        }
        
        table {
        width: 100%;
        border-collapse: collapse;
        }
        
        th, td {
        padding: 8px 12px;
        border: 1px solid #ddd;
        text-align: left;
        line-height:1.3;
        }
        
        th {
        background-color: #f4f4f4;
        }
        
        @media (max-width: 600px) {
        th, td {
            font-size: 12px;
            padding: 6px 12px;
            min-width: 90px;
        }
        }
        @media (max-width: 399px) {
            .credit-card {
                width:100%; 
            }
            .default-method {
                margin-top: -6px;
                margin-right: -18px;
            }
            }