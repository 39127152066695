.menu-items ul{
    padding:0;
}
.menu-items ul li{
    list-style-type:none;
    font-size: 20px;
    margin-bottom: 10px 
}
.menu-items ul li:last-child{
    border-top: 2px solid #bdc5bc;
}
.menu-button{
    padding:10px;
    z-index:999;
}
.menu-button button{
    width: 35px;
    height: auto;
    padding: 0;
    border: none;
    background: none;
    display: flex;
    min-height: 30px;
    flex-direction: column;
    justify-content: unset;
    align-items: flex-end;
}
.menu-button button span{
    height: 4px;
    background-color: black;
    display: block;
    margin-bottom: 5px;
    border-radius: 40px;
}
.menu-button button span:first-child{
    width: 60%;
}
.menu-button button span:nth-child(2){
    width: 100%;
}
.menu-button button span:last-child{
    width: 70%;
}
.menu-button button.close span:first-child{
    transform: rotate(45deg);
    margin-top: 8px;
    width: 85%;
}
.menu-button button.close span:nth-child(2){
    display: none;
}
.menu-button button.close span:last-child{
    transform: rotate(-45deg);
    margin-top: -9px;
    width: 85%;
}
.menu-button button span:last-child{
    margin-bottom:0;
}
.cope-ahead-menu{
    position: relative;
    top: 0px;
    left:0;
    width: 100%;
    height: 100vh;
    z-index: 99;
    background: #fff;
    display: none;
    justify-content: center;
    align-items: center;
    
}
.cope-ahead-header{
    position: absolute;
    background: transparent;
    width: 100%;
    z-index: 9;
    max-width:calc(100%);
}
.cope-ahead-menu.active{
    position: absolute;
    display:flex;
}
.cope-ahead-header-inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.cope-ahead-header.active{
    position:fixed;
    left:0;
    top:0;
}

.menu-items a {
    font-weight: 700;
    font-size: 24px;
    line-height: 44px;
    color: #000;
}
.app-title{
    font-size: 22px;
    font-weight: 700;
    letter-spacing: -0.4px;
    line-height: 26px;
    text-transform: capitalize;
}
.user-img{
    padding: 10px;
}
.app-logo-img {
    width: 80px;
    padding: 10px 10px;
    display: inline-table;
    z-index: 999;
}
.cope-ahead-header-inner > a{
    position: relative;
    z-index: 9999;
}
.cope-ahead-header-inner .user-img img, .app-logo-img img{
    width: 60px;
}
@media screen and (max-width: 380px) {
    .app-title {
        font-size: 18px;
    }
    .option-wrap-inner .title{
        font-size: 22px;
    }
    .option-wrap-inner .description{
        font-size:20px;
    }
}