.hidden-field{
    display:none;
}
#create-course-form h3{
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    margin-top: 0;
    text-transform: capitalize;
    width: 100%;
    display: block;
}
.form-fields-area .check-wrap .checkbox{
    margin-bottom:0;
    margin-top:0;
}
.form-area-inner .form-fields-area label{
    margin-bottom: 15px;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    text-transform: capitalize;
    /* position: relative; */
    padding-bottom:0;
    display: flex;
    align-items: center;
}
.check-wrap .checkbox input {
    width: 25px;
    height: 23px;
    color: #000;
    border: 1px solid #B4A8A8;
    flex:none;
}
.form-footer .theme-btn{
    font-size: 18px;
    text-transform: uppercase;
    line-height: 21px;
    padding: 5px;
    font-family: raleway;
}
.form-footer{
    text-align: center;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
}
.rca .title{
    font-size: 26px;
    line-height: 36px;
    font-weight: 700;
    margin-top: 0px;
    margin-bottom: 15px;
}
.rca .desc{
    font-size: 16px;
    line-height: 26px;
    font-weight: 700;
    font-style: normal;
}
.rca .desc button {
    padding: 5px 38px 5px 10px;
    border: 1px solid #518888;
    font-size: 12px;
    line-height: 14px;
    color: #437273;
    font-weight: 700;
    background: #5188881a;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url(./../Media/material-symbols_arrow-drop-down-circle-outline.svg);
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 1px;
    background-size: 13%;
}
.form-popup{
    position: absolute;
    display: flex;
    top: 0;
    width: 100%;
    height: 100%;
    background: #00000061;
    padding: 20px;
    left: 0;
    justify-content: center;
    align-items: center;
    display:none;
}
.form-popup .check-wrap{
    padding: 30px;
    background: #fff;
    border-radius: 20px;
    position:relative;
}
.form-popup.active-form{
    display:flex;
    position:fixed;
}
.content-wrap.rca{
    margin-top:-56px;
    height:auto;
    padding-bottom:0;
}
.content-wrap.rca .form-area-inner{
    position:relative;
}
.form-footer.hidden-field{
    display:none;
}
.rca #create-course-form .title span{
    color:#F2843D;
}