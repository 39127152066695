.panel {
    border: 1px solid #dbdbdb;
    margin-bottom: 4px;
    position:relative;
    border-radius: 20px;
    overflow: hidden;
}
.question {
    border-bottom: 1px solid #cecece;
    padding: 20px;
    background: #f1f1f1;
    font-weight: 700;
    text-align: left;
    position:relative;
}
.answer {
    padding: 20px;
    text-align:left;
}
.faq{
    background: #fff;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    padding:20px;
}
.panel .answer{
    display:none;
}
.panel.active-panel .answer{
    display:block;
}
.panel.active-panel .question, .panel:hover .question{
    background: #d8e8de;
}
.panel .question:before{
    content: "";
    width: 10px;
    height: 2px;
    display: block;
    position: absolute;
    right: 20px;
    top: calc(50% - 1px);
    background: #000;
}
.panel .question:after{
    content: "";
    width: 10px;
    height: 2px;
    display: block;
    position: absolute;
    right: 20px;
    top: calc(50% - 1px);
    background: #000;
    transform:rotate(90deg);
}
.panel.active-panel .question:after{
    display:none;
}
