.carousel{
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;
    border-radius: 15px;
    position: relative;
  }
  .inner{
    white-space: nowrap;
    transition: transform 0.3s;
  }
  .carousel-item{
    display:inline-flex;
    align-items: center;
    justify-content: center;
    height:100%;
    background-color:#fff;
    padding:20px 20px;
    font-size: small;
    width:100%;
    
  }
  .carousel-item-title{
    text-align:left;
    font-weight: 700;
    white-space: normal;
    font-size:24px;
    line-height:32px;
    color:#214D4D;
    text-transform: capitalize;
  }
  .carousel-item-text{
    margin: 20px 0 10px 0;
    white-space: normal;
    text-align: left;
    font-weight: 500;
    line-height: 24px;
    font-size: 14px;
  }
  .carousel-buttons{
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
    position: relative;
    z-index: 8;
    margin-top: -26px;
    margin-right: 30px;
  }
  .finish-button{
    padding:0 20px;
  }
  .main-background{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100vh;
    background: linear-gradient(-45deg, #ffffff, #f2f3f5, #dddde7, #c3c5d1);
  background-size: 300% 300%;
  animation: gradient 5s ease infinite;
  }
  .button-left-arrow, .button-right-arrow{
    background: none;
    border-color: transparent;
    width: 50px;
    height: 50px;
    background: #214D4D;
    border-radius: 50%;
    padding:10px;
  }
  .button-left-arrow span, .button-right-arrow span{
    width: 22px;
    height: 3px;
    background: #D9B175;
    display: block;
    position: relative;
    border-radius: 30px;
  }
  .button-left-arrow span:before{
    content: "";
    width: 13px;
    height: 3px;
    background: #D9B175;
    display: block;
    transform: rotate(315deg);
    position: absolute;
    margin-top: -5px;
    left:0;
    border-radius: 30px;
  }
  .button-left-arrow span:after{
    content: "";
    width: 13px;
    height: 3px;
    background: #D9B175;
    display: block;
    transform: rotate(45deg);
    position: absolute;
    margin-top: 5px;
    left:0;
    border-radius: 30px;
  }
  .button-right-arrow span:before{
    content: "";
    width: 13px;
    height: 3px;
    background: #D9B175;
    display: block;
    transform: rotate(45deg);
    position: absolute;
    margin-top: -5px;
    right: 0;
    border-radius: 30px;
  }
  .button-right-arrow span:after{
    content: "";
    width: 13px;
    height: 3px;
    background: #D9B175;
    display: block;
    transform: rotate(315deg);
    position: absolute;
    margin-top: 5px;
    right: 0;
    border-radius: 30px;
  }
  .indicator-buttons {
    background: transparent;
    border: none;
    padding: 2px;
  }
  .material-symbols-outlined.indicator-symbol {
    background: #ffffff;
    display: block;
    width: 23px;
    height: 7px;
    border-radius: 50px;
  }
  .material-symbols-outlined.indicator-symbol-active {
    background: #D9B175;
    display: block;
    width: 23px;
    height: 7px;
    border-radius: 50px;
  }
  .close-button{
    position: absolute;
    top: 10px;
    text-align: right;
    display: block;
    width: 22px;
    height: 22px;
    right: 15px;
    border: 2px solid #000;
    border-radius: 50%;
  }
  .close-button span:before{
    content: "";
    width: 12px;
    height: 2px;
    background: #000;
    display: block;
    transform: rotate(315deg);
    position: absolute;
    margin-top: 8px;
    right: 3px;
  }
  .close-button span:after {
    content: "";
    width: 12px;
    height: 2px;
    background: #000;
    display: block;
    transform: rotate(45deg);
    position: absolute;
    margin-top: 8px;
    right: 3px;
  }
  .octagonWrap {
    width: 150px;
    height: 150px;
    float: left;
    position: relative;
    overflow: hidden;
}
.octagon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    transform: rotate(45deg);
    background: #040d36;
    border: 5px solid rgb(4 13 54);
    box-shadow: rgb(255 255 255) 0px 0px 0px 3px inset;
    display: flex;
    align-items: center;
    justify-content: center;
}
.octagon:before {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    transform: rotate(45deg);
    content: '';
    border: inherit;
    box-shadow: rgb(255 255 255) 0px 0px 0px 3px inset;
}
  .octGraphic{
    display: inline-flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .octText {
    transform: rotate(315deg);
    color: #fff;
    font-size: 35px;
    text-transform: uppercase;
}
.octText span span{
  color:#c33e3e;
  font-weight: 600;
}
.indicators{
  display: flex;
  position: relative;
  margin-top: -35px;
  margin-bottom: 25px;
}