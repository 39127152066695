@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
body {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  color:#000;
  letter-spacing: .4px;
}
html, body {
  height: 100%;
  margin:0;
}
a{
  text-decoration:none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.container{
  width:479px;
  margin: 0 auto;
  position: relative;
}
.container, .content-wrap{
  height:100%;
}
.common-bg{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  background: #D8E8DE;
  /* background: linear-gradient(-45deg, #ffffff, #ffffff, #ffffff, #ffffff); */
  background-size: 300% 300%;
  animation: gradient 5s ease infinite;
  height:100%;
  min-height: 100vh;
  /* overflow-y:scroll; */
}
.content-wrap{
  padding: 30px;
    /* background: #D8E8DE; */
    /* height:100vh */
}
.content-wrap.qc, .skill .content-wrap{
  margin-top:-40px;
  position: relative;
}
.skills .content-wrap.rca-skills{
  padding-top:20px;
}
.skills .content-wrap{
  border-radius:20px;
  padding: 0 10px 10px 10px;
}
.skills .content-wrap-inner{
  border-radius: 20px;
  padding: 15px;
}
.activities .content-wrap {
  border-radius:20px;
}
.box-breathing .content-wrap{
  margin-top:-40px;
 }
 .skill.box-breathing .banner-content{
  padding-bottom:30px;
  
 }
.content-wrap{
    position: relative;
    height: 100%;

}
.white-bg{
  background: #fff;
}
.row{
  display:flex;
  flex-wrap:wrap;
}
.col-2{
  flex:50%;
}
@keyframes gradient {
0% {
  background-position: 0% 50%;
}
50% {
  background-position: 100% 50%;
}
100% {
  background-position: 0% 50%;
}
}
*,
*:before,
*:after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
}

@media only screen and (max-width: 479px) {
  .container{
    width:100%;
  }
}
.body {
  font-family: "Caudex", serif;
  font-weight: 400;
  font-style: normal;
}
.option-box-inner{
    display: block;
}
.option-box {
  background: #ffffff;
    padding: 10px;
    margin: 10px;
    border-radius: 20px;
    width: calc(50% - 20px);
    float:left;
    min-height: 132px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px #0000001a;
}
.option-box h4 {
  margin: 0;
  margin-bottom: 4px;
  font-weight: 500;
}
.option-box p {
  margin: 0;
  font-size:14px;
  font-style: italic;
}
.option-box-wrap {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  padding-top:42px;
}
.option-wrap-inner .title {
  font-size: 27px;
  font-weight: 700;
  line-height: 38px;
  color: #214D4D;
  margin-bottom: 30px;
}
.option-wrap-inner .description {
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
}
.featured-box{
  display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.featured-box-inner h2 {
  font-size: 20px;
  font-weight: 500;
}
.featured-box-inner a{
  display: block;
  margin-top:20px;
}
.back-btn {
  font-size:0px;
  border:0px;
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(1);
  width: 22px;
  height: 22px
}
.back-btn::after,
.back-btn::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 3px
}
.back-btn::after {
  width: 8px;
  height: 8px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  bottom: 7px;
}
.back-btn::before {
  width: 16px;
  height: 2px;
  bottom: 10px;
  background: currentColor
}
.back-btn:hover::after {
  border-color:#403b81;
}
.back-btn:hover::before{
  background: #403b81;
}
.filter .head-title{
  display: flex;
  justify-content: space-between;
}
.theme-btn, .StripeElement + button{
  font-size: 20px;
  font-weight: 700;
  line-height: 23.48px;
  color: #fff;
  background: #214D4D;
  padding: 10px 20px;
  border-radius: 50px;
  border: 1px solid #214D4D;
  display: block;
  width:100%
}
/**************App Info***************/
.welcome{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 88vh;
}
.welcome .logo{
  margin-bottom:20px;
}
.welcome .logo img{
  width:155px;
}
.welcome .heading h2{
  font-size: 27px;
  font-weight: 700;
  line-height: 31.7px;
  letter-spacing: -0.4px;
  color: #214D4D;
  margin-bottom:20px;
}
.welcome .description p{
  font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    text-align: center;
    margin-bottom:20px
}
.welcome .btn{
  width:100%;
}
.welcome .btn button{
  margin:15px 0;
}

.mask-with-ellips{
  -webkit-mask-image: url(Media/white-ellipse.svg);
  mask-image: url(Media/white-ellipse.svg);
  mask-size: 100%;
  mask-repeat: no-repeat;
  background-color: #fff;
  -webkit-mask-position-y: bottom;
  width: calc(100% + 250px);
  left: -125px;
  position: relative;
  z-index: 9;
  height: 230px;
}
.mask-with-ellips img{
  width:75%;
}
.banner-box > img{
  width:100%;
  margin-bottom:-30px;
}
.banner-box{
  position:relative;
  background: #d8e8de;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding-top:40px;
  overflow: hidden;
}

.banner-wrap{
  position: absolute;
    bottom: 0;
    z-index: 0;
    display:flex;
    justify-content: space-between;
    padding: 30px;
    width: 100%;
    padding-bottom:44px;
    align-items: flex-end;
}
.banner-wrap.rc{
  padding-bottom: 70px;
}
.banner-content{
  flex: 0 0 47%;
  color: #fff;
  text-align: left;
  padding-bottom: 0px;
}
.banner-right-img {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  margin-bottom: -15px;
  margin-right: -15px;
}
.skill .banner-right-img {
  margin-bottom: -35px;
  margin-right: -25px;
}
.skill .banner-content{
  flex: 0 0 45%;
  display: flex;
  padding-bottom: 45px;
  align-items: flex-end;
}

.banner-content .description{
  color: #fff;
  text-align: left;
  font-size: 14px;
  line-height: 1.3;
  margin-bottom:10px;
  margin-top:0;
}
.banner-content .title{
  font-size: 16px;
  line-height: 1.5;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.banner-content .title span{
  color: #F2843D;
}
.skill .banner-content .title{
  font-size: 24px;
    font-weight: 700;
    line-height: 1.4;
    text-transform: capitalize;
}
form select, .choosePlan select{
  border: none;
    border-bottom: 1px solid;
    color: #A6A6A6;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    font-family: raleway;
    outline: none;
}
form select:focus {
  outline: none;
}
.form-header {
  color: #fff;
  background: #214d4d;
  border-radius: 20px;
  padding: 30px;
  margin-bottom:30px;
}
.form-area-inner .form-header h4 {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
}
.form-area-inner .form-header p {
  font-size: 15px;
  font-style: normal;
  margin-top: 0;
  line-height: 18px;
  font-weight: 500;
}
.form-fields-area {
  background: #fff;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 0 20px #0000000f;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url(./Media/material-symbols_arrow-drop-down-circle-outline.svg);
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: -2px;
  padding: 3px 1px;
}
.register-form select{
  width:100%;
  padding-left:40px;
}
.logo{
  justify-content: center;
  margin-bottom: 35px;
  display: flex;
}
.activities.logo{
  justify-content: center;
  margin-bottom: 35px;
  display: flex;
  margin-top:60px;
}
.logo img{
  width:125px;
}
.contact .banner-box .title {
  font-size: 30px;
    line-height: 32px;
    color: #214D4D;
    font-weight: 700;
    margin-bottom: 5px;
}
.contact .banner-box h4 {
  font-size: 24px;
    line-height: 32px;
    color: #214D4D;
    font-weight: 700;
    margin-bottom: 25px;
    margin-top: 0;
}
.contact .banner-box p {
  font-size: 18px;
    line-height: 30px;
    font-weight: 500;
}
.contact .banner-box p span{
    font-weight: 700;
}
.contact .banner-box  img{
  width:75%;
}
.featured-box .them-btn{
  font-size: 18px;
    line-height: 21px;
}
.profile-area-inner{
    background: #fff;
    padding: 30px;
    border-radius: 20px;
}
.footer-main{
  margin-bottom: -30px;
}
/************RCA**************/
.rca-graphic{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.rca-graphic-inner{
  width: 300px;
  position: relative;
  height: 125px;
}
.rca-graphic-inner .step{
  position: absolute;
}
.rca-graphic-inner .rca-step-1{
  left: 4px;
    bottom: 44px;
}
.rca-graphic-inner .rca-step-2{
  left: 31px;
    bottom: 87px;
}
.rca-graphic-inner .rca-step-3{
  left: calc(50% - 46px);
  bottom: 116px;
}
.rca-graphic-inner .rca-step-4{
  right: 31px;
  bottom: 87px;
}
.rca-graphic-inner .rca-step-5{
  right: 4px;
  bottom: 44px;
}
.circle-logo {
  position: absolute;
  left: calc(50% - 44px);
  bottom: 0;
  z-index: 9;
}
.circle-logo svg {
  width: 88px;
}
.rca-graphic img{
  position: absolute;
  margin: 0;
  left: calc(50% - 29px);
  padding: 17px;
  top: calc(50% - 25px);
  
}
.circle-logo:before {
  content: "";
  width: 80px;
  height: 4px;
  display: block;
  background: #fff;
  position: absolute;
  left: -100px;
  top: 62px;
  border-radius: 30px;
}
.circle-logo:after {
  content: "";
  width: 80px;
  height: 4px;
  display: block;
  background: #fff;
  position: absolute;
  right: -100px;
  top: 62px;
  border-radius: 30px;
}
.step:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  background: #fff;
  border-radius: 30px;
  border: 2px solid #000;
  z-index: 9 ;
}
.rca-step-1:before{
  bottom:0;
  right:-10px;
}
.rca-step-2:before{
  bottom:-10px;
  right:20px;
}
.rca-step-3:before{
  bottom:-15px;
  right:20px;
}
.rca-step-3:after{
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 30px;
  border: 2px solid #000;
  bottom: -15px;
  left: 20px;
  z-index: 9;
}
.rca-step-4:before{
  bottom:-10px;
  left:20px;
}
.rca-step-5:before{
  bottom:0px;
  left:-10px;
}
.rca-graphic-inner .step.active svg path{
  fill:#d16a3b;
}
.rca-step-1 img{
  left: calc(50% - 30px);
  top: calc(50% - 25px);
}
.rca-step-2 img{
  left: calc(50% - 28px);
  top: calc(50% - 30px);
}
.rca-step-3 img{
  left: calc(50% - 37px);
  top: calc(50% - 42px);
}
.rca-step-4 img{
  left: calc(50% - 35px);
  top: calc(50% - 35px);
}
.rca-step-5 img{
  left: calc(50% - 40px);
  top: calc(50% - 37px);
}
.circle-logo img{
  width: 85px;
  left: calc(50% - 42px);
  top: calc(50% - 38px);
}
.our-circle-logo{
  height: 80px;
  overflow: hidden;
}
.our-circle-logo:before {
  content: "";
  width: 126px;
  height: 126px;
  border: 3px solid #fff;
  display: block;
  border-radius: 100px;
  position: relative;
  left: calc(50% - 63px);
  top: 15px;
}
.rca-graphic-inner .step.active:before, .rca-graphic-inner .step.active:after{
  border-color:#fff;
}
.our-circle-logo:after {
  content: "";
  width: 120px;
  height: 120px;
  display: block;
  background-image: linear-gradient(360deg, transparent 50%, #d16a3b 50%);
  position: relative;
  margin-top: -108px;
  left: calc(50% - 60px);
  border-radius: 100px;
}
.payment-details, .plan-details{
  padding: 5px;
  text-align: left;
}
.payment-details .heading, .plan-details .heading{
  font-weight: 500;
  font-size:20px;
  margin-top:0;
}
.payment-details .heading{
  margin-top:30px;
}
button.theme-btn.disabled {
  background: #848a8a;
  border-color: #848a8a;
  cursor: not-allowed;
}
.StripeElement{
  padding: 10px 0;
  border-bottom: 1px solid #a6a6a6;
  margin-bottom: 10px;
}
.user-dashboard{
  margin-top: 60px;
    border-bottom: 1px solid #00000017;
    padding-bottom: 10px;
    text-align: left;
}
.subscription-item, .order-item {
  background: #fff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 0 10px #0000001a;
    margin-top: 12px;
    text-align: left;
    font-size: 14px;
    line-height: 26px;
    text-transform: capitalize;
    /* display: flex; */
    flex-direction: column;
}
.subscription-item div span:first-child, .method-inner div span:first-child{
  color: #046f86;
  font-weight: 700;
  flex: 0 0 50%;
}
.my-subscriptions .head-title {
  font-size: 18px;
  width:100%;
}
.subscription-item .theme-btn, .billing-address .theme-btn, .method-section .theme-btn, .my-subscriptions.details .theme-btn, .order-item .theme-btn, .thank-you-page .theme-btn{
  font-size: 14px;
  width: fit-content;
  padding: 5px 20px;
  background: #437273;
  border: 1px solid #214d4d12;
  margin-top: 10px;
  line-height:1.3;
}
.thankyou-links{
  display:flex;
  justify-content: center;
  align-items: center;
}
.order-item .theme-btn{
  margin-top:0;
}
.subscription-item .theme-btn:hover, .billing-address .theme-btn:hover {
  background: #214D4D;
  border: 1px solid #214D4D;
}
.subscription-nav{
  display: flex;
  justify-content: flex-start;
  font-size: 13px;
  font-weight: 600;
  align-items: center;
  margin-top:20px;
  margin-bottom:20px;
}
.subscription-nav .subscription-nav-item{
  margin-right: 15px;
}
.subscription-nav .subscription-nav-item:last-child{
  margin-right: 0;
}
.subscription-nav .subscription-nav-item.active-item{
  border-bottom: 1px solid #000;
}

.my-subscriptions.details{
  margin-top: 50px;
  border-top: 1px solid #0000002b;
  padding-top: 20px;
}
.billing-address, .method-wrap {
  background: #fff;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px #0000001a;
  margin-top: 12px;
  text-align: left;
  font-size: 14px;
  line-height: 26px;
  display: flex;
  flex-direction: column;
}
.billing-address .address{
    text-align: left;
    margin-bottom: 10px;
    padding-bottom: 10px;
    text-transform: capitalize;
}
.subscription-table {
  margin-bottom: 10px;
}
.common-inner{
  height:100vh;
}
.billing-address .theme-form.popup, .method-section .theme-form.popup{
  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #000000a3;
    padding: 30px;
    height: 100vh;
    z-index: 9;
    display:none;
}
.method-section .form-wrap, .billing-address .form-wrap{
  background: #fff;
    padding: 30px;
    border-radius: 20px;
    position: relative;
    width: 100%;
}
.billing-address .theme-form label, .method-section .theme-form label {
  padding-bottom: 10px;
}
.billing-address label:before, .billing-address label:after,  .method-section label:before, .method-section label:after{
  bottom: 15px;
}
.billing-address form{
  width:100%;
}
.billing-address .popup.active-form, .method-section .popup.active-form{
  display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
}
.billing-address .form-footer, .method-section .form-footer{
  justify-content: flex-start;
}
.billing-address .form-footer .theme-btn, .method-section .form-footer .theme-btn {
  padding: 5px 20px;
    margin-right: 10px;
    font-size:14px;
    text-transform:capitalize;
}
.rca .form-popup label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
}
.create-method-link{
  margin-top:25px;
}
.create-method-link span {
  border-bottom: 1px solid #214D4D;
  color: #214D4D;
  font-weight: 600;
}
.create-method-link span:hover {
  border-bottom: 1px solid #97abab;
    color: #97abab;
}
button.close-btn.theme-btn {
  position: absolute;
  right: -7px;
  top: -21px;
  height: 32px;
  width: 32px;
  line-height: 28px;
  padding: 0;
  border: 2px solid #fff;
  background: #214d4d;
}

.lodding{
  display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    background: #ffffffc2;
}
.lodding-text {
  padding: 33px;
  border-radius: 20px;
  font-size: 20px;
}
.footer.links{
  display:flex;
}
.content-wrap-inner.subscription-form{
  margin-top: 60px;
}
.terms-page .footer-main{
  overflow:hidden;
}
.contact {
  padding: 70px 30px 30px;
}
.order-table .table-responsive, .subscription-table .table-responsive {
    width: 100%;
    overflow-x: auto;
    border-radius: 10px;
    border: 1px solid #437273;
}
.order-table .table-responsive tr.order-head, .subscription-table .table-responsive tr.order-head{
  background: #437273;
  color: #fff;
  height: 48px;
  padding: 0;
}
.order-table .table-responsive th, .subscription-table  .table-responsive th {
  background: transparent;
  border-color: #437273;
}
.order-table .table-responsive td, .subscription-table .table-responsive td{
  height: 48px;
  font-weight: 600;
    color: #437273;
    font-size: 14px;
    text-transform: capitalize;
}
.order-table .table-responsive td, .order-table .table-responsive th{
  min-width:auto;
}
.order-table tbody tr, .subscription-table tbody tr{
  border-bottom: 10px solid #dddddd85;
}
.react-confirm-alert-overlay{
  background: rgb(0 0 0 / 70%) !important;
}
.react-confirm-alert{
  padding: 30px;
}
.react-confirm-alert-body {
  font-family: raleway !important;
  width: 100% !important;
  color: #000000 !important;
  line-height: 1.5;
  font-size:14px;
}
.react-confirm-alert-body > h1 {
  margin-top: 0;
  color: #214d4d;
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
}
.react-confirm-alert-button-group > button{
  font-size: 14px !important;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 20px !important;
    background: #437273 !important;
    border: 1px solid #214d4d12 !important;
    line-height: 1.3 !important;
    border-radius: 42px !important;
}
.subscription-table, .order-table{
  display:grid;
}
.checkmark {
  display: inline-block;
  width: 22px;
  height: 22px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}

.checkmark_stem {
  position: absolute;
    width: 3px;
    height: 12px;
    background-color: #00c01a;
    left: 14px;
    top: 0px;
}

.checkmark_kick {
  position: absolute;
    width: 9px;
    height: 3px;
    background-color: #00c01a;
    left: 8px;
    top: 12px;
}
.checked {
  position: absolute;
  top: -2px;
  right: 8px;
}
.rca .desc button{
  position:relative;
}
.thank-you-page .content-wrap{
  padding-top: 70px;
}
@media only screen and (max-width: 359px) {
  svg {
    width: 67px;
  }
  .rca-graphic-inner .rca-step-3 {
    left: calc(50% - 34px);
    bottom: 109px;
  }
  .rca-step-3:after{
    bottom: -4px;
    left: 20px;
  }
  .rca-step-3:before {
    bottom: -4px;
    right: 20px;
  }
  .rca-graphic-inner .rca-step-4 {
    right: 40px;
    bottom: 85px;
  }
  .rca-graphic-inner .rca-step-2 {
    left: 41px;
    bottom: 83px;
  }
}