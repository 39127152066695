
.form-area-inner{
    text-align:left;
}
.form-area-inner h4{
    margin-bottom:0;
    font-size:20px;
    font-weight:500;
}
.form-area-inner p{
    font-size: 14px;
    font-style: italic;
    margin-top: 0;
}
.theme-form input {
    width: 100%;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid #A6A6A6;
    padding: 6px;
    margin-top: 10px;
    font-size: 16px;
    font-family: raleway;
    font-weight: 400;
    line-height: 19px;
    color:#A6A6A6;
}
.theme-form input:focus {
    outline: none;
}
.theme-form label {
    text-align: left;
    padding-bottom: 30px;
    display: flex;
    align-items: flex-end;
    position: relative;
}
label:before, label:after {
    content: "";
    width: 24px;
    height: 24px;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 35px;
}
label.user:before {
    background: url(../../Media/user.svg);
}
label.email:before {
    background: url(../../Media/mail.svg);
}
label.password:before {
    background: url(../../Media/lock.svg);
}
label.choosePlan:before {
    background: url(../../Media/lock.svg);
}
.eye-icon {
    background: url(../../Media/eye.svg);
    right: 0;
    width: 34px;
    background-size: contain;
    background-repeat: no-repeat;
    height: 25px;
    position: absolute;
}
.hide:before{
    content: "";
    width: 33px;
    height: 1px;
    background: #a6a6a6;
    display: block;
    position: absolute;
    top: 8px;
    right: 0;
    transform: rotate(125deg);
}
input.field-with-icon{
    padding-left:40px;
}
.form-area-inner label{
    padding-bottom: 10px;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    text-transform: capitalize;
}
.form-footer{
    text-align:center;
    padding:15px 0;
}
.form-footer p{
    font-style:normal;
    margin-top:10px;
}
.check-wrap {
    text-align: left;
    font-size: 14px;
}
.flex-wrap{
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    margin-top:5px;
}
.flex-wrap.column{
    flex-direction: column;
}
.flex-wrap a{
    color:#437273;
}
.flex-wrap .checkbox input, .check-wrap .checkbox input{
    width: 21px;
    height: 19px;
    color: #000;
    border: 1px solid #B4A8A8;
}
.check-wrap .checkbox{
    margin-bottom: 30px;
    margin-top: 15px;
}
.checkbox {
    display: flex;
    align-items: flex-start;
}
.checkbox input{
    width: auto;
    margin: 0 7px 0px 0;
}

.login-form a{
    text-decoration:none;
  }
input[type='checkbox']{
text-align:left;
width: auto;
}
.login-register-head .title{
    font-weight: 700;
    font-size:22px;
    line-height:25.83px
}
.login-register-switcher {
    font-size: 17px;
    font-weight: 700;
    line-height: 20px;
    display:flex;
    justify-content: center;
}
.login-register-switcher .active a {
    color: #214D4D;
}
.login-register-switcher .inactive a {
    color: #A6A6A6;
}
.login-register-switcher .active:after {
    content: "";
    width: revert-layer;
    height: 1px;
    background: #000;
    display: block;
    top: 6px;
    position: relative;
}
.login-register-switcher span {
    padding: 15px;
    margin-bottom: 20px;
}
.login-register-footer {
    height: 150px;
    background: #214D4D;
    overflow: hidden;
    margin-top: -100px;
    width: calc(100% + 60px);
    left: -30px;
    position: relative;
}
.login-register-footer:before{
    content:"";
    width:150%;
    border-radius:100%;
    height: 150px;
    background: #214D4D;
}
div#errorMessage {
    color: red;
    padding-bottom: 20px;
}
.forget-reset-password .banner-box{
    padding-top:0;
    z-index:2;
}
.forget-reset-password .content-wrap{
    margin-top:-70px;
}
.forget-reset-password .theme-form{
    background: #ffffff;
    padding: 30px;
    border-radius: 20px;
    position: relative;
    z-index: 9;
    box-shadow: 0 2px 7px #00000038;
}
.forget-reset-password .theme-form input{
    background:#ffffff;
}
.forget-reset-password h4.title{
    font-size:25px;
    line-height:1.3;
}
.forget-reset-password .footer-main{
    padding-top:15px;
}
.error-message{
    color:red;
    padding: 10px;
}
@media only screen and (max-width: 600px) {
    .login-form{
      font-size:small;
    }
  }