.theme-btn1 {
    padding: 7px 20px;
    background: #304674;
    color: white;
    text-decoration: none;
    font-size: 11px;
    font-weight: 400;
    border-radius: 50px;
    transition: 0.5s;
    display: inline-block;
}
.theme-btn1:hover {
    background: #1d3056;
    transition: 0.5s;
}
.theme-btn2 {
    text-decoration: none;
    color: #c70404;
    font-size: 18px;
    padding-top: 5px;
    display: inline-block;
    margin:0;
    font-weight:700;
}